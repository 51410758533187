import { isBoolean, isEmpty, isFunction } from "lodash";
import {
  useConfirmStore,
  type ConfirmationInput,
  type ConfirmationLikeInput,
} from "../private/confirm";
import type { FieldInputsWithoutKey } from "~/layers/ui/modules/VDynamicForm/runtime/types";

type ConfirmationWithInputsLikeInput<
  A extends any[],
  I extends Record<keyof I, any>
> =
  | (ConfirmationInput & { inputs: I })
  | { (...a: A): ConfirmationInput & { inputs: I } };

export const useConfirm = () => {
  const { add } = useConfirmStore();

  /**
   * Open the confirmation dialog immediately.
   */
  const confirm = (options: ConfirmationInput) => {
    return add(options);
  };

  /**
   * Create a function that can be used to open dialogs
   * with the provided config.
   */
  const define = (options: ConfirmationInput) => {
    return () => add(options);
  };

  /**
   * Confirm if true/false with the provided options.
   * @returns
   */
  const checkConfirm = async (options: ConfirmationInput) => {
    try {
      await add(options);
      return true;
    } catch {
      return false;
    }
  };

  /**
   * Protect the provided function behind a confirmation dialog.
   * The dialog is opened whenever the returned function is called.
   * The provided function is only called if confirmed.
   *
   * Function call resolves `false` if confirmation was rejected.
   *
   * Confirm dialog is skipped if options resolves a boolean value.
   * If `true`, the provided function is called.
   */
  const wrapConfirm = <A extends any[], R>(
    fn: { (...a: A): R | Promise<R> },
    options: ConfirmationLikeInput<A>
  ) => {
    return async (...a: A): Promise<R | false> => {
      const o = isFunction(options) ? options(...a) : options;

      if (isBoolean(o)) {
        return o ? fn(...a) : Promise.resolve(false);
      }

      return add(o)
        .then(() => fn(...a))
        .catch(() => Promise.resolve(false));
    };
  };

  /**
   * Similar to `wrapConfirm`, but the provided function is
   * called with the form input results.
   *
   * Function call resolves `false` if confirmation was rejected.
   */
  const wrapConfirmInput = <
    A extends any[],
    R,
    I extends FieldInputsWithoutKey
  >(
    fn: { (inputs: Record<keyof I, any>, ...a: A): R | Promise<R> },
    options: ConfirmationWithInputsLikeInput<A, I>
  ) => {
    return async (...a: A): Promise<R | false> => {
      const o = isFunction(options) ? options(...a) : options;

      return add(o)
        .then((x: any) => {
          if (isEmpty(x) && o.inputsDefaults) {
            x = o.inputsDefaults;
          }
          return fn(x, ...a);
        })
        .catch(() => Promise.resolve(false));
    };
  };

  return {
    confirm,
    define,
    checkConfirm,
    wrapConfirm,
    wrapConfirmInput,
  };
};
